<!-- Btra Botones de Mto -->

<template>
  <div class="btramto">
    <btra
        v-if="btra_particular"
        :btra_particular="mount_btra"
        :btra_modulo="btra_modulo"
        @onEvent="event_btramto">
    </btra>
  </div>
</template>


<script>

  import plugs from "@/common/general_plugs";
  const btra = () => plugs.groute("btra.vue", "comp");

  export default {
    components: { btra },
    props: {
      btra_permisos: { type: [Array, Object], required:true },
      btra_particular: { type: [Object, String], required:true },
      btra_modulo: { type: String, default: "" },
      estado: { type: [Number, String], default: "" }
    },


    computed: {

      // devuelvo array de botones a mostrar
      mount_btra() 
      {        
        // extraigo la btra de Mto
        var bp= this.get_btraMto();
        
        // filtro array de botones por sus permisos 
        if (this.btra_modulo!== "m" && this.btra_modulo!== "xm") return this.filtrar_btns(bp);

        // si es una botonera de tipo mto, monto array botones según la acción recibida y filtro por sus permisos          
        return this.std_btns(bp);
      }

    },

    methods: {

      // gestor de eventos
      event_btramto(evt) 
      {
          console.log("*** event_btramto. Emit btramto: ", evt, " ***");
          this.$emit("onEvent", { accion: evt.accion });
      },

      // según btra_modulo, extraigo el array de btns de Mto de la btra particular
      get_btraMto() 
      {      
          if (!this.btra_modulo) return [];

          // copio btra particular
          let bp = JSON.parse(JSON.stringify(this.pass_btra_toObject()));

          // obtengo el array de btns de Mto según el tipo de módulo recibido
          switch (this.btra_modulo) {
            case "mrow":
            case "mtop":  
            case "cab_detalle":
              if (bp.row["mto"] && bp.row["mto"] !== false) return bp.row.mto;
              return [];

            case "m":
            case "detalle":
            case "docs":
            case "footer":
            case "top":
            case "xm":
            case "R":             
              if (bp.footer["mto"] && bp.footer["mto"] !== false) return bp.footer["mto"];
              return [];

            default:
              return [];
        }
      },

      // según la acción recibida devuelvo una botonera estandar filtrada por sus permisos
      std_btns(bp) 
      {                       
          let btns = [];
          switch (this.estado) {
            case "ver": // ver            
              btns = [0, 1, 1, 1, 0, 0, 1];
              break;

            case "nuevo":  // nuevo
            case "editar": // editar
              btns = [0, 0, 0, 0, 1, 1, 1];
              break;

            default:
              btns = [0, 1, 0, 0, 0, 0, 1];
          }

          // filtro según permisos          
          return this.filtrar_btns(btns.map((elem, idx) => (bp[idx] ? elem : 0)));
      },

      // filtro botones por sus permisos
      filtrar_btns(btns) 
      {      
          btns.forEach((accion, idx) => {
            btns[idx]= accion && this.btra_permisos.mto[idx]? Number(this.btra_permisos.mto[idx]) : accion;
          });

          return btns;
      },

      // convierto la btra particular recibida como String en un Objeto
      pass_btra_toObject() {        
        if (typeof this.btra_particular === "object") return this.btra_particular;        
        return this.$cfg.btra_tipo[this.btra_particular];        
      }

      
    }
  };
</script>
